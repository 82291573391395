import React from "react";
import data from "./quotes.json";
import { Typography } from "@mui/material";
function Quote() {
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const rndInt = randomIntFromInterval(0, 99);
  const quote = data[rndInt]["q"];
  const author = `- ${data[rndInt]["a"]}`;

  //   console.log(quote);
  return (
    <div>
      <Typography
        variant="h1"
        color="white"
        align="center"
        mt={{ xs: 1, lg: 5 }}
        fontSize={{ xs: 24, lg: 45 }}
        sx={({ breakpoints, typography: { size } }) => ({
          [breakpoints.down("md")]: {},
        })}
      >
        {quote}
      </Typography>

      <br />
      <Typography
        variant="body1"
        color="white"
        textAlign="center"
        px={{ xs: 8, lg: 12 }}
        mt={1}
        fontSize={{ xs: 20, lg: 30 }}
      >
        {author}
      </Typography>

      {}
    </div>
  );
}
export default Quote;
