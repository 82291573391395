import { Routes, Route } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import Geography from "./scenes/geography";
import Login from "./scenes/login";
import { Container } from "@mui/system";
import RequireAuth from "./components/RequireAuth";
import { useAddress } from "@thirdweb-dev/react";

const WHITELIST = {
  Admin: "0x4c37354800361f68c896803d945823bb6c57931f",
  Visitor: "anything",
};

function App() {
  
  const [theme, colorMode] = useMode();
  const address = useAddress();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              {/* Member Pages */}
                <Route path="/dashboard" element={<Dashboard address={address}/>} />
                <Route
                  element={<RequireAuth allowedRoles={[WHITELIST.Admin]} />}
                >
                  <Route path="/dashboard" element={<Dashboard />} />
                </Route>
                <Route path="/team" element={<Team />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/form" element={<Form />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/geography" element={<Geography />} />
                
              {/* Public Pages */}
              <Route path="/" element={<Login />} />
              <Route
                path="*"
                element={
                  <>
                    <Container>
                      <h1>Page not found.</h1>
                    </Container>
                  </>
                }
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
