import { Container, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Header from "../../components/Header";
import loginImage from "./login_bg.jpg";
import loginLogo from "./login_bg_logo.svg";
import { useAddress } from "@thirdweb-dev/react";
// import Quote from "../../components/Quote";
// import { tokens } from "../../theme";
// import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Quote from "../../components/Quote";
import arrow from "./arrow.svg";
// import Spline from "@splinetool/react-spline";

const Login = () => {
  const address = useAddress();
  const navigate = useNavigate();
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  if (!address) {
    return (
      <Box m="20px">
        <Box display="flex" justifyContent="right">
          <Header title="LOGIN" subtitle="Please connect your wallet" />
          <Box marginLeft="auto" maxHeight="5em" component="img" src={arrow} />
        </Box>

        <Box display="flex">
          {/* <Container>
            <Spline scene="https://prod.spline.design/KxTxJQuGOn3jWWBB/scene.splinecode" />
          </Container>
          */}
          <Box
            minHeight="75vh"
            width="100%"
            sx={{
              backgroundImage: `url(${loginImage})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Container>
              <Grid
                container
                item
                xs={12}
                lg={8}
                justifyContent="center"
                mx="auto"
                
              >
                <Quote></Quote>
                <Container
                  mt="5em"
                  component="img"
                  maxWidth="true"
                  alt="Use the connect wallet button to login"
                  src={loginLogo}
                />
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    );
  } else {
    navigate("/dashboard");
  }
};

export default Login;
