import { useAddress } from "@thirdweb-dev/react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowedWallets }) => {
  const address  = useAddress();
  const location = useLocation();
    console.log("Testing " + address.valueOf)
  return address.valueOf?.find((address) => allowedWallets?.includes(address)) ? (
    <Outlet />
  ) : address?.Admin(
    <Navigate to="/login" state={{ from: location }} replace />
  ) 
};

export default RequireAuth;
